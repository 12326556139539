<template>
  <div class="iss-detail">
    <a-form
      ref="formRef"
      class="detail-wrapper iss-main"
      layout="vertical"
      :model="task"
      :rules="rules"
      :validate-trigger="['change', 'blur']"
    >
      <div class="banner">
        <img v-show="task.banner" :src="task.banner" />
        <a-form-item
          v-if="editInfo"
          name="banner"
          extra="建议上传宽高比为16:9，大小不超过1M，格式为JPG、JPEG或PNG的图片"
        >
          <iss-image-upload
            v-model:value="task.banner"
            accept=".jpe,.jpeg,.jpg,.png"
          />
        </a-form-item>
      </div>
      <div :class="{ info: true, editing: editInfo }">
        <a-descriptions :column="{ sm: 1, md: 2, lg: 3, xxl: 4 }">
          <template #title>
            <template v-if="!editInfo">
              <a-tag :color="status[0]">{{ status[1] }}</a-tag>
              <span class="title">
                {{ task.campaignName }}
              </span>
            </template>
          </template>
          <template
            #extra
            v-if="
              this.$store.state.common.orgIds ||
              this.$store.state.common.userId ===
                this.$store.state.common.CreateUser
            "
          >
            <a-space v-if="editInfo" :size="10">
              <a-button @click="handleCancel">取消</a-button>
              <a-button type="primary" @click="handleSave">保存</a-button>
            </a-space>
            <a
              v-else
              class="iss-btn"
              type="link"
              @click="
                $has(`${$route.params.type}:update`).then(
                  () => (editInfo = true)
                )
              "
            >
              <component :is="icons['EditOutlined']" class="mr-8" />
              <span> 编辑信息</span>
            </a>
          </template>
          <template v-if="!editInfo">
            <a-descriptions-item>
              <template #label>
                <span>
                  <component
                    :is="icons['CalendarOutlined']"
                    class="iconsStyle mr-5"
                  />
                  开始日期
                </span>
              </template>
              {{ task.startTime }}
            </a-descriptions-item>
            <a-descriptions-item>
              <template #label>
                <span>
                  <component
                    :is="icons['CalendarOutlined']"
                    class="iconsStyle mr-5"
                  />
                  结束日期
                </span>
              </template>
              {{ task.endTime }}
            </a-descriptions-item>
            <a-descriptions-item>
              <template #label>
                <span>
                  <component
                    :is="icons['BankOutlined']"
                    class="iconsStyle mr-5"
                  />
                  国家/省市
                </span>
              </template>
              {{ task.country }}
              {{ task.province ? ` • ${task.province}` : '' }}
              {{ task.city ? ` • ${task.city}` : '' }}
            </a-descriptions-item>
            <a-descriptions-item v-if="task.location">
              <template #label>
                <span>
                  <component
                    :is="icons['EnvironmentOutlined']"
                    class="iconsStyle mr-5"
                  />
                  地址
                </span>
              </template>
              {{ task.location }}
            </a-descriptions-item>
            <a-descriptions-item>
              <template #label>
                <span>
                  <component
                    :is="icons['BookOutlined']"
                    class="iconsStyle mr-5"
                  />
                  任务目标
                </span>
              </template>
              {{ ['推广传播', '留资获客'][task.target - 1] }}
            </a-descriptions-item>
            <a-descriptions-item>
              <template #label>
                <span>
                  <component
                    :is="icons['ContactsOutlined']"
                    class="iconsStyle mr-5"
                  />
                  目标数量
                </span>
              </template>
              {{ task.campaignScale }}
            </a-descriptions-item>
            <a-descriptions-item>
              <template #label>
                <span>
                  <component
                    :is="icons['DollarOutlined']"
                    class="iconsStyle mr-5"
                  />
                  预算
                </span>
              </template>
              {{ task.budget }}
            </a-descriptions-item>
            <a-descriptions-item>
              <template #label>
                <span>
                  <component
                    :is="icons['UserOutlined']"
                    class="mr-5 iconsStyle"
                  />
                  创建人
                </span>
              </template>
              {{ task.createUserName }}
            </a-descriptions-item>
            <a-descriptions-item v-if="task.campaignDesc">
              <template #label>
                <span>
                  <component
                    :is="icons['ProfileOutlined']"
                    class="iconsStyle mr-5"
                  />
                  描述
                </span>
              </template>
              {{ task.campaignDesc }}
            </a-descriptions-item>
          </template>
        </a-descriptions>
        <a-row v-if="editInfo" :gutter="20">
          <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="名称" name="campaignName">
              <a-input
                v-model:value="task.campaignName"
                autocomplete="off"
                placeholder="请输入名称"
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="日期" name="date">
              <a-range-picker
                v-model:value="task.date"
                show-time
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="国家/省市" style="margin-bottom: 0">
              <a-row :gutter="10">
                <a-col :span="8">
                  <a-form-item name="country" :wrapper-col="{ span: 24 }">
                    <a-select
                      v-model:value="task.country"
                      show-search
                      disabled
                      placeholder="请选择国家"
                      :filter-option="filterOption"
                      @change="handleChangeByCountry"
                    >
                      <a-select-option
                        v-for="item in countryList"
                        :key="item.id"
                        :value="item.itemNameCn"
                        :item="item"
                      >
                        {{
                          $store.state.account.language === 'zh-CN'
                            ? item.itemNameCn
                            : item.itemNameEn
                        }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item name="province" :wrapper-col="{ span: 24 }">
                    <a-select
                      v-model:value="task.province"
                      allow-clear
                      show-search
                      placeholder="请选择省份"
                      @change="handleChangeByProvince"
                    >
                      <a-select-option
                        v-for="item in provinceList"
                        :key="item.id"
                        :value="item.itemCode"
                        :item="item"
                      >
                        {{
                          $store.state.account.language === 'zh-CN'
                            ? item.itemNameCn
                            : item.itemNameEn
                        }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-form-item name="city" :wrapper-col="{ span: 24 }">
                    <a-select
                      v-model:value="task.city"
                      allow-clear
                      show-search
                      placeholder="请选择城市"
                      @change="handleChangeByCity"
                    >
                      <a-select-option
                        v-for="item in cityList"
                        :key="item.id"
                        :value="item.itemCode"
                      >
                        {{
                          $store.state.account.language === 'zh-CN'
                            ? item.itemNameCn
                            : item.itemNameEn
                        }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
          </a-col>
          <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="地址" name="location">
              <a-input v-model:value="task.location" placeholder="请输入地址" />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="任务目标" name="target">
              <a-radio-group v-model:value="task.target">
                <a-radio :value="1">推广传播</a-radio>
                <a-radio :value="2">留资获客</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :sm="24" :lg="12" :xxl="8" v-if="webSiteUser">
            <a-form-item
              label="同步数据"
              name="websiteSwitch"
              extra="开启同步数据后，报名参会的联系人数据会同步到阵地用户"
            >
              <a-radio-group v-model:value="task.websiteSwitch">
                <a-radio :value="false">不同步</a-radio>
                <a-radio :value="true">同步</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="目标数量" name="campaignScale">
              <a-input-number
                v-model:value="task.campaignScale"
                style="width: 100%"
                :min="0"
                :placeholder="`请输入目标${
                  ['触及', '留资'][task.target - 1]
                }人数`"
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :lg="12" :xxl="8">
            <a-form-item label="预算" name="budget">
              <a-input-number
                v-model:value="task.budget"
                :min="0"
                placeholder="请输入预算"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :lg="12" :xxl="16">
            <a-form-item label="描述" name="campaignDesc">
              <a-textarea
                v-model:value="task.campaignDesc"
                showCount
                :maxlength="100"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </div>
    </a-form>
    <div class="iss-main">
      <div class="flex mb-9">
        <span class="fs-16 fw-500">工具集</span>
        <div
          v-if="
            this.$store.state.common.orgIds ||
            this.$store.state.common.userId ===
              this.$store.state.common.CreateUser
          "
        >
          <a-space v-if="visible" :size="10">
            <a-button @click="handleClickByCancel">取消</a-button>
            <a-button type="primary" @click="handleClickByConfirm"
              >确认</a-button
            >
          </a-space>
          <a v-else type="link" @click="visible = true" class="iss-btn">
            <SettingOutlined class="mr-8" /> <span>配置工具</span>
          </a>
        </div>
      </div>
      <div
        v-for="item in toolsList"
        v-show="visible || active[item.tacticId]"
        :key="item.tacticId"
        :class="{ 'app-list': true }"
      >
        <div class="app-title">{{ item.tacticName }}</div>
        <div class="app-wrapper">
          <div
            v-for="child in item.appList"
            v-show="visible || active[item.tacticId]?.includes(child.id)"
            :key="child.id"
            :class="{
              'app-item': true,
              'item-edit': visible && child.status && !item.required,
              'item-active': active[item.tacticId]?.includes(child.id),
              'item-border':
                visible && active[item.tacticId]?.includes(child.id),
              'item-required': visible && child.status && item.required,
              disabled: !child.status,
            }"
            @click="handleClickByApp(item, child)"
          >
            <a-avatar :size="30" class="mr-12">
              <template #icon>
                <component :is="icons[child.icon]" class="fs-18" />
              </template>
            </a-avatar>
            <span>{{ child.appName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
// import { useStore } from 'vuex';
import {
  Avatar,
  Col,
  DatePicker,
  Descriptions,
  Form,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import { SettingOutlined } from '@ant-design/icons-vue';
import IssImageUpload from '@/components/imageUpload';
import { dateFormat } from '@/utils';
import timeZoneList from '@/dictionaries/timeZone.json';
import activityApi from '@/api/activity';
import dictionaryApi from '@/api/dictionary';
var equal = require('equals');

export default {
  components: {
    ARow: Row,
    ACol: Col,
    ATag: Tag,
    ASpace: Space,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AForm: Form,
    AFormItem: Form.Item,
    ARangePicker: DatePicker.RangePicker,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    AInputNumber: InputNumber,
    AAvatar: Avatar,
    IssImageUpload,
    SettingOutlined,
  },
  data() {
    const datacenter = this.$store.state.account.menus.find(
      menu => menu.menuKey === 'datacenter'
    );
    const webSiteUser = datacenter?.children?.find(
      menu => menu.menuKey === 'websiteUser'
    );
    const required = { required: true, whitespace: true, message: '不能为空' };
    return {
      icons,
      timeZoneList,
      webSiteUser,
      languageList: [],
      countryList: [],
      provinceList: [],
      cityList: [],
      templateId: '',
      task: { date: null },
      status: ['success', '进行中'],
      toolsList: [],
      initActive: '',
      active: {},
      editInfo: false,
      visible: false,
      rules: {
        campaignName: [required, { max: 100, message: '不可超过100字' }],
        location: [{ max: 100, message: '不可超过100字' }],
        date: { ...required, type: 'array' },
        campaignScale: [
          { ...required, type: 'number' },
          {
            validator: (rule, value, cbfn) => {
              if (Number(value) > 100000000) {
                cbfn('数值过大，请重新输入');
              }
              cbfn();
            },
          },
        ],
        budget: [
          { ...required, type: 'number' },
          {
            validator: (rule, value, cbfn) => {
              if (Number(value) > 100000000) {
                cbfn('数值过大，请重新输入');
              }
              cbfn();
            },
          },
        ],
      },
    };
  },
  created() {
    this.getDictionary();
    this.getTask();
  },
  methods: {
    unique(arr) {
      const res = new Map();
      return arr.filter(
        arr => !res.has(arr.tacticId) && res.set(arr.tacticId, 1)
      );
    },

    getTask() {
      const { type, id, campaignId } = this.$route.params;
      activityApi.getCampaignById('', { campaignId: campaignId }).then(res => {
        this.$store.commit('common/setCampaignOrgId', res?.orgId);
        this.$store.commit('common/setCreateUser', res?.createUser);
      });
      // authorityOrgByUserId 返回的数组有没有包含 orgId ，||  authorityOrgByUserId返回的 userId  === res.createUser
      // 就表示有权限
      const { userId } = this.$store.state.account.user;
      activityApi.authorityOrgByUserId('', { userId: userId }).then(res => {
        let orgIds = res?.orgIds.includes(
          this.$store.state.common.CampaignOrgId
        );
        this.$store.commit('common/setOrgIds', orgIds);
        this.$store.commit('common/setUserId', res.userId);
      });

      activityApi
        .getItemById(`${type}:detail`, id)
        .then(({ baseInfo, tacticAppList, templateId }) => {
          const { startTime, endTime, province, orgId } = baseInfo;
          this.$store.commit('common/setCampaignOrgId', orgId);
          Object.assign(this.task, baseInfo, {
            date: [moment(startTime), moment(endTime)],
          });
          const now = new Date().getTime();
          const start = new Date(startTime).getTime();
          const end = new Date(endTime).getTime();
          if (now < start) {
            this.status = ['processing', '未开始'];
          } else if (now > end) {
            this.status = ['default', '已结束'];
          }
          activityApi.getAppList('', { templateId }).then(data => {
            this.toolsList = data;
            // console.log('this.toolsList', this.toolsList);
            let appListCopy = [...tacticAppList];
            appListCopy = appListCopy.map(App => {
              return { tacticId: App.tacticId, appList: App.appList };
            });
            let requiredApps = [];
            let tacticRequiredAppList = [];
            data?.forEach(i => {
              i.required && i.appList?.length > 0 && requiredApps.push(i);
            });
            tacticAppList?.forEach(i => {
              i.required && tacticRequiredAppList.push(i);
            });
            tacticAppList = this.unique(tacticAppList.concat(requiredApps));
            tacticAppList = tacticAppList.map(app => {
              const temp = requiredApps.find(
                item => item.tacticId === app.tacticId
              );
              temp && (app = temp);
              return app;
            });

            // let temp = this.unique(requiredApps.concat(tacticAppList));
            let appList = [];
            tacticAppList.forEach(i => {
              i.appList.forEach(j => {
                j.status && appList.push({ tacticId: i.tacticId, appId: j.id });
              });
            });
            const isEqual = equal(
              appListCopy,
              tacticAppList.map(({ tacticId, appList }) => ({
                tacticId,
                appList,
              }))
            );
            // temp?.length > tacticAppList?.length &&
            isEqual ||
              activityApi
                .updateApp(`${type}:edittool`, { appList, campaignId })
                .then(() => {
                  return this.getTask();
                });
          });
          let active = {};
          tacticAppList.forEach(i => {
            active[i.tacticId] = i.appList.map(a => a.id);
          });
          this.initActive = JSON.stringify(active);
          this.active = active;
          this.cityList = this.provinceList.find(
            i => i.itemCode === province
          )?.children;
        });
    },
    getDictionary() {
      const { type } = this.$route.params;
      dictionaryApi
        .getItemList(`${type}:update`, { code: 'LOCAL_LANGUAGE' })
        .then(data => (this.languageList = data));
      dictionaryApi
        .getItemTree(`${type}:update`, { code: 'AREA' })
        .then(data => (this.countryList = data));
      dictionaryApi
        .getItemTree(`${type}:update`, { code: 'CHINA_AREA' })
        .then(data => (this.provinceList = data));
    },
    dateFormat,
    handleClickByApp(item, child) {
      if (this.visible) {
        if (!item.required && child.status) {
          const temp = this.active[item.tacticId];
          if (temp) {
            const index = temp.findIndex(i => i === child.id);
            index > -1 ? temp.splice(index, 1) : temp.push(child.id);
          } else {
            this.active[item.tacticId] = [child.id];
          }
        }
      } else if (child.status) {
        const { apps } = this.$store.state.common;
        const { path, params } = this.$route;
        if (apps.find(i => i.appId === child.id)) {
          this.$router.push(`${path}/app${child.entry}`);
        } else {
          this.$router.push(
            `${path}/${child.id}?path=${child.entry.replace(
              /:id/,
              params.eventId
            )}`
          );
        }
      } else {
        message.info('敬请期待～');
      }
    },
    handleClickByCancel() {
      this.visible = false;
      this.active = JSON.parse(this.initActive);
    },
    handleClickByConfirm() {
      const { type, campaignId } = this.$route.params;
      let appList = [];
      for (const tacticId in this.active) {
        appList = appList.concat(
          this.active[tacticId].map(appId => ({ tacticId, appId }))
        );
      }
      activityApi
        .updateApp(`${type}:edittool`, { appList, campaignId })
        .then(() => {
          this.visible = false;
          this.$message.success('操作成功');
          this.getTask();
        });
    },
    filterOption(input, { item }) {
      return item.itemNameCn.indexOf(input) >= 0;
    },
    handleChangeByCountry() {
      this.task.province = null;
      this.task.city = null;
    },
    handleChangeByProvince(value, option) {
      option || (this.task.province = '');
      this.cityList = option ? option.item.children : [];
      this.task.city = '';
    },
    handleChangeByCity(value, option) {
      option || (this.task.city = '');
    },
    handleCancel() {
      this.$refs.formRef.resetFields();
      this.editInfo = false;
    },
    handleSave() {
      const { type } = this.$route.params;
      this.$refs.formRef.validate().then(() => {
        const date = this.task.date;
        this.task.startTime = date[0].format('YYYY-MM-DD HH:mm:ss');
        this.task.startTimestamp = date[0].toDate().getTime();
        this.task.endTime = date[1].format('YYYY-MM-DD HH:mm:ss');
        this.task.endTimestamp = date[1].toDate().getTime();
        activityApi.update(`${type}:update`, this.task).then(() => {
          this.$message.success('操作成功');
          this.editInfo = false;
          this.getTask();
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.iss-detail {
  max-height: calc(100vh - 56px);
  overflow-y: auto;
}
.detail-wrapper {
  display: flex;
  margin: 16px;
  padding: 20px;
  .banner {
    margin-right: 15px;
    width: 200px;
    height: 120px;
    img {
      margin-bottom: 10px;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
  .info {
    flex: 1;
    .title {
      font-size: 18px;
      vertical-align: middle;
    }
  }
}
.app-list {
  margin-bottom: 15px;
  padding-top: 10px;
  .app-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    //&::before {
    //  content: '';
    //  margin-right: 8px;
    //  width: 4px;
    //  height: 20px;
    //  background: linear-gradient(138deg, @primary-5 0%, @primary-color 100%);
    //  border-radius: 2px;
    //}
  }
  .app-wrapper {
    display: flex;
    gap: 20px;
    //margin: 0 0 0 18px;
    .app-item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 15px 20px;
      width: 186px;
      border-radius: 0px 0px 0px 0px;
      cursor: pointer;
      &.item-edit {
        color: #1d2129;
        background: #fff;
        //box-shadow: 0px 2px 8px 2px #e5e6eb;
        border-radius: 4px;
        border: 1px solid #e5e6eb;

        :deep(.ant-avatar) {
          color: @primary-color;
          background: #f0f5ff;
          .anticon {
            color: @primary-color;
          }
        }
        &::before {
        }
      }
      &.item-required {
        cursor: not-allowed;
        &::before {
          content: '✓';
          //content: '✕';
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 16px;
          width: 16px;
          right: -1px;
          top: -1px;
          font-size: 10px;
          color: #fff;
          border-radius: 0px 4px 0px 2px;
          background-color: #5879f8;
          border-radius: 0px 4px 0px 2px;
          //border: 1px solid #5979F8;
        }
      }

      &.item-active {
        //box-shadow: 0px 2px 8px 2px #f7f8fa;
        border: 1px solid #e5e6eb;
        //box-shadow: 0px 2px 8px 2px #e5e6eb;
        border-radius: 4px;
        //color: #5979F8;
        background: #ffffff;

        //background: linear-gradient(90deg, @primary-5 0%, @primary-color 100%);
        //box-shadow: 0px 2px 4px 0px @primary-4;
        :deep(.ant-avatar) {
          //background-color: #fff;
          color: @primary-color;
          background: #f0f5ff;
          .anticon {
            color: @primary-color;
          }
        }
        &.item-edit::before {
          content: '✓';
          //content: '✕';
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 16px;
          width: 16px;
          right: -1px;
          top: -1px;
          font-size: 10px;
          color: #fff;
          border-radius: 0px 4px 0px 2px;
          background-color: #5879f8;
          border-radius: 0px 4px 0px 2px;
          //border: 1px solid #5979F8;
        }
      }
      &.item-border {
        border: 1px solid #5979f8;
        color: #5979f8;
      }
      &:hover {
        box-shadow: 0px 2px 16px 2px #f2f3f5;
        //border: 1px solid #e5e6eb;
        //box-shadow: 0px 2px 16px 2px #F2F3F5;
      }
      &.disabled {
        color: @primary-color;
        background: #f1f1f1;
        box-shadow: 0px 2px 4px 0px @primary-3;
        cursor: not-allowed;
        :deep(.ant-avatar) {
          background-color: @primary-color;
          .anticon {
            color: #fff;
          }
        }
      }
    }
  }
}

.iconsStyle {
  color: #999999;
}
.ant-avatar {
  border-radius: 0;
}
.iss-btn {
  color: #4e5969;
  &:hover {
    color: @primary-color;
  }
}
.iss-detail /deep/ .ant-descriptions-title {
  //width: 90%;
}
.info {
  /deep/ .ant-descriptions-extra {
    width: 82px;
  }
  &.editing /deep/ .ant-descriptions-extra {
    width: 138px;
  }
}
</style>
